import { CreationableTransferType, OutgoingTransferType, TransferType } from 'src/modules/operations/transfers/types'
import { ROUTE } from 'constants/routes'
import BankSvg from 'assets/icons/globe.svg'
import CardSvg from 'assets/icons/card.svg'
import EUR from 'assets/icons/euro.svg'
import ExchangeSvg from 'src/assets/icons/exchange-2-outlined.svg'
import GBP from 'assets/icons/gbp.svg'
import GlobusSvg from 'assets/icons/globe.svg'
import InternalSvg from 'assets/icons/internal.svg'
import MassSvg from 'assets/icons/mass.svg'
import NapasSvg from 'assets/logos/ic_napas.svg'
import NetellerSvg from 'assets/logos/ic_neteller.svg'
import PaxumSvg from 'assets/logos/ic_paxum_2.svg'
import PercentSvg from 'assets/icons/percent.svg'
import React from 'react'
import SkrillSvg from 'assets/logos/ic_skrill.svg'
import USD from 'assets/icons/dollar.svg'
import UsdtSvg from 'assets/logos/usdt-circle.svg'
import UsdtTrc20Svg from 'assets/icons/crypto/usdt-trc20.svg'
import WalletSvg from 'assets/icons/wallet-2.svg'

export const CURRENCY_TO_ICON: Record<string, React.ComponentType> = {
    USD,
    EUR,
    GBP,
}

export const ROUTE_TO_TRANSFER_TYPES: Record<string, OutgoingTransferType[]> = {
    [ROUTE.TRANSFERS_INTERNAL]: ['PRACTIKA'],
    [ROUTE.TRANSFERS_BANK]: ['IFX', 'SEPA'],
    [ROUTE.TRANSFERS_WALLET]: ['BANK_VND', 'PaxumP2P', 'PaysafeSkrill', 'PaysafeNeteller'],
    [ROUTE.TRANSFERS_CARD]: ['WORLDCARD', 'NuveiCardOut'],
    [ROUTE.EXCHANGE]: ['FX'],
    [ROUTE.TRANSFERS_CRYPTO]: ['CryptoUSDT'],
    [ROUTE.TRANSFERS_MASS]: [],
}

export const TRANSFER_TYPE_TO_ROUTE_MAP: Record<CreationableTransferType, (params: string) => string> = {
    PRACTIKA: () => ROUTE.TRANSFERS_INTERNAL,
    IFX: () => ROUTE.TRANSFERS_BANK,
    BANK_VND: () => ROUTE.TRANSFERS_WALLET,
    CryptoUSDT: () => ROUTE.TRANSFERS_CRYPTO,
    // SEPA: () => ROUTE.TRANSFERS_BANK,
    PaysafeSkrill: () => ROUTE.TRANSFERS_WALLET,
    PaysafeNeteller: () => ROUTE.TRANSFERS_WALLET,
    PaxumP2P: () => ROUTE.TRANSFERS_WALLET,
    WORLDCARD: () => ROUTE.TRANSFERS_CARD,
    NuveiCardOut: () => ROUTE.TRANSFERS_CARD,
    FX: () => ROUTE.EXCHANGE,
    TopUp1: (cardid: string) => `${ROUTE.CARDS}/${cardid}/top-up`,
    Card2Card1: (cardid: string) => `${ROUTE.CARDS}/${cardid}/transfer`,
}

export const TRANSFER_TYPE_TO_ICON = (type: TransferType, payload?: string) =>
    ({
        // Internal
        PRACTIKA: <InternalSvg width={24} />,
        // Wallet
        BANK_VND: <NapasSvg width={42} />,
        PaxumP2P: <PaxumSvg width={42} />,
        PaysafeSkrill: <SkrillSvg width={42} />,
        PaysafeNeteller: <NetellerSvg width={42} />,
        CryptoUSDT: payload ? (
            {
                tron: <UsdtTrc20Svg style={{ position: 'relative', left: 4 }} width={40} />,
            }[payload]
        ) : (
            <UsdtSvg width={36} />
        ),
        // Bank
        IFX: <GlobusSvg width={28} />,
        IFXIn: <GlobusSvg width={28} />,
        ClearJunction: <GlobusSvg width={28} />,
        SEPA: <GlobusSvg width={28} />,
        SWIFT: <GlobusSvg width={28} />,
        // ClearJunction: <GlobusSvg width={28} />,
        // Card transfer
        WORLDCARD: <CardSvg width={28} />,
        NuveiCardOut: <CardSvg width={28} />,
        // Practika fee
        SERVICE: <PercentSvg width={20} />,
        // Exchange
        UNKNOWN: <ExchangeSvg width={28} />,
        FX: <ExchangeSvg width={28} />,
        // Cards
        PlasticCard1: <CardSvg width={28} />,
        VirtualCard1: <CardSvg width={28} />,
        TopUp1: <CardSvg width={28} />,
        Block1: <CardSvg width={28} />,
        Change3ds1: <CardSvg width={28} />,
        Card2Card1: <CardSvg width={28} />,
        ActivatePlastic1: <CardSvg width={28} />,
        ChangePin1: '',
        send_credit: '',
    })[type]

export const PAGE_VARIANTS_FADE_UP = {
    initial: {
        opacity: 0,
        y: 25,
    },
    in: {
        opacity: 1,
        y: 0,
    },
    out: {
        opacity: 0,
    },
}

export const PAGE_VARIANTS_FADE_RIGHT = {
    initial: {
        opacity: 0,
        x: -100,
    },
    in: {
        opacity: 1,
        x: 0,
    },
    out: {
        opacity: 0,
        x: 100,
    },
}

export const MOTION_TRANSITION = {
    duration: 0.3,
}

export const TRANSFER_TABS = (props?: { showExchange?: boolean; showMass?: boolean; showCrypto?: boolean }) =>
    [
        {
            text: 'Internal transfer',
            value: ROUTE.TRANSFERS_INTERNAL,
            prefix: <InternalSvg width={19} />,
            defaultTransferType: 'PRACTIKA',
        },
        {
            text: 'Bank transfer',
            value: ROUTE.TRANSFERS_BANK,
            prefix: <BankSvg width={19} />,
            defaultTransferType: 'IFX',
        },
        props?.showExchange && {
            text: 'Exchange',
            value: ROUTE.EXCHANGE,
            prefix: <ExchangeSvg width={19} />,
            defaultTransferType: 'FX',
        },
        {
            text: 'Card transfer',
            value: ROUTE.TRANSFERS_CARD,
            prefix: <CardSvg width={19} />,
            defaultTransferType: 'WORLDCARD',
        },
        {
            text: 'Wallet transfer',
            value: ROUTE.TRANSFERS_WALLET,
            prefix: <WalletSvg width={19} />,
            defaultTransferType: 'BANK_VND',
        },
        props?.showCrypto && {
            text: 'Crypto transfer',
            value: ROUTE.TRANSFERS_CRYPTO,
            prefix: <CardSvg width={19} />,
            defaultTransferType: 'CryptoUSDT',
        },
        props?.showMass && {
            text: 'Mass transfer',
            value: ROUTE.TRANSFERS_MASS,
            prefix: <MassSvg width={19} />,
        },
    ].filter(Boolean) as {
        text: string
        value: string
        prefix: React.ReactElement
        defaultTransferType?: TransferType
    }[]
