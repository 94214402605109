import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { IFX_AccountIncoming, IFX_AccountOutgoing, IFX_ProcessedTransfer } from 'src/interfaces/operations/bank/ifx'
import { ReactNode } from 'react'
import { TransferType, UnionProcessedTransfer } from 'src/modules/operations/transfers/types'
import { capitalizeFirstLetter } from 'src/utils'
import Bugsnag from '@bugsnag/js'
import CardRequisites from 'components/modals/TransferDetails/CardRequisites'
import IsService from 'services/IsService'
import styled from 'astroturf/react'

const FormatService = {
    camelCase2Title: (camelCase: string): string => {
        const result = camelCase.replace(/([A-Z])/g, ' $1')
        return result.charAt(0).toUpperCase() + result.slice(1)
    },
    cardPanString: (string: string): string => string.replace(/(?!^)(?=(?:\d{4})+(?:\.|$))/gm, ' '),
    cardPan: (pan: string): ReactNode => {
        const panLength = 16
        const firstIndex = pan.indexOf('*')
        const lastIndex = pan.lastIndexOf('*')

        const firstNumbers = pan.slice(0, firstIndex)
        const lastNumbers = pan.slice(lastIndex, pan.length)

        const numbersLength = firstNumbers.length + lastNumbers.length
        const formattedPan = (firstNumbers + '•'.repeat(panLength - numbersLength) + lastNumbers).replace('*', '•')

        return (
            <>
                {Boolean(pan) ? (
                    <>
                        <span>{formattedPan.slice(0, 4)}</span> <span>{formattedPan.slice(4, 8)}</span>{' '}
                        <span>{formattedPan.slice(8, 12)}</span> <span>{formattedPan.slice(12, 16)}</span>
                    </>
                ) : (
                    '•••• •••• •••• ••••'
                )}
            </>
        )
    },
    money2Number: (money?: string | number) => (money ? Number(money.toString().replaceAll(',', '')) : 0),
    money: (
        amount: string | number | undefined,
        currency?: string,
        decimalCount = 2,
        decimal = '.',
        thousands = ',',
    ): string => {
        if (amount !== undefined) {
            decimalCount = Math.abs(decimalCount)
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount

            const negativeSign = amount < 0 ? '-' : ''

            const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
            const j = i.length > 3 ? i.length % 3 : 0

            return (
                negativeSign +
                (currency || '') +
                (j ? i.substr(0, j) + thousands : '') +
                i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
                (decimalCount
                    ? decimal +
                      Math.abs(Number(amount) - Number(i))
                          .toFixed(decimalCount)
                          .slice(2)
                    : '')
            )
        }
        return ''
    },
    iban: (iban: string) => {
        return `${iban.slice(0, 4)} ${iban.slice(4, 8)} ${iban.slice(8, 12)} ${iban.slice(12, 16)} ${iban.slice(
            16,
            20,
        )} ${iban.slice(20, 24)}`
    },
    transfer2fromIBAN: (transfer: IFX_ProcessedTransfer) => {
        try {
            if (transfer.documentType === 'IN') {
                return FormatService.iban((transfer.account as IFX_AccountIncoming).iban)
            } else {
                return transfer.accountFrom
            }
        } catch (e) {
            Bugsnag.notify(e)
            return 'INVALID PAYER IBAN'
        }
    },
    transfer2beneficiaryIbanOrAccountNumber: (transfer: IFX_ProcessedTransfer) => {
        try {
            return FormatService.iban(
                transfer.documentType === 'IN'
                    ? ' '
                    : (transfer.account as { beneficiary: IFX_AccountOutgoing }).beneficiary.iban ||
                          (transfer.account as { beneficiary: IFX_AccountOutgoing }).beneficiary.accountnumber,
            )
        } catch (e) {
            Bugsnag.notify(e)
            return 'INVALID BENEFICIARY IBAN OR ACCOUNT NUMBER'
        }
    },
    transfer2fromAccount: (transfer: UnionProcessedTransfer, extended?: boolean): string => {
        try {
            if (extended) {
                return transfer.type === 'IFX'
                    ? transfer.documentType === 'IN'
                        ? transfer.account.paymentDetails?.payerRequisite?.name || transfer.account?.fullname || '—'
                        : `${transfer.userInfoName}\n ${transfer.accountFrom}`
                    : transfer.documentType === 'IN'
                      ? transfer.account?.account
                      : transfer.accountFrom
            } else {
                return transfer.type === 'SWIFT' || transfer.type === 'IFX' ? (
                    transfer.documentType === 'IN' ? (
                        transfer.account.otherParty
                    ) : (
                        transfer.userInfoName
                    )
                ) : transfer.type === 'ClearJunction' ? (
                    transfer.account?.paymentDetails?.payerRequisite?.name || 'N/A'
                ) : transfer.documentType === 'IN' ? (
                    transfer.account?.account
                ) : transfer.type === 'Card2Card1' ? (
                    <CardRequisites onlyCardName cardid={transfer.account?.srccardid} />
                ) : (
                    transfer.accountFrom
                )
            }
        } catch (e) {
            Bugsnag.notify(e)
            return 'INVALID PAYER ACCOUNT'
        }
    },
    reduceCryptoAddress: (address: string) => {
        try {
            return `${address.slice(0, 6)}...${address.slice(-4)}`
        } catch (e) {
            return undefined
        }
    },
    reduceCryptoTransactionHash: (txHash: string) => {
        try {
            return `${txHash.slice(0, 6)}...${txHash.slice(-4)}`
        } catch (e) {
            return undefined
        }
    },
    transfer2toAccount: (transfer: UnionProcessedTransfer, extended?: boolean): string | ReactNode => {
        try {
            if (extended) {
                return IsService.bankTransfer(transfer.type) && transfer.documentType === 'OUT'
                    ? `${
                          transfer.account.type === 'individual'
                              ? `${transfer.account.firstName} ${transfer.account.lastName}`
                              : transfer.account.name
                      }\nIBAN: ${transfer.account.iban}\nSWIFT/BIC: ${transfer.account.swiftBic}`
                    : transfer.type === 'FX'
                      ? transfer.account.targetAccount
                      : transfer.type === 'SERVICE' || IsService.cardIssueTransfer(transfer.type)
                        ? 'Practika Finance LTD'
                        : transfer.documentType === 'IN'
                          ? transfer.accountTo
                          : transfer.type === 'WORLDCARD'
                            ? `Card (${FormatService.cardPanString(transfer.account?.cardNumber)})`
                            : transfer.type === 'CryptoUSDT'
                              ? `${capitalizeFirstLetter(
                                    transfer.account?.network,
                                )} (${FormatService.reduceCryptoAddress(transfer.account?.address)})`
                              : transfer.account?.account
            } else {
                return IsService.bankTransfer(transfer.type) && transfer.documentType === 'OUT' ? (
                    transfer.account?.beneficiary?.name ||
                        `${transfer.account.beneficiary.firstname} ${transfer.account.beneficiary.lastname}`
                ) : transfer.type === 'FX' ? (
                    transfer.account.targetAccount
                ) : transfer.type === 'SERVICE' || IsService.cardIssueTransfer(transfer.type) ? (
                    'Practika Finance LTD'
                ) : transfer.documentType === 'IN' ? (
                    transfer.accountTo
                ) : transfer.type === 'WORLDCARD' ? (
                    `Card (${FormatService.cardPanString(transfer.account?.account)})`
                ) : IsService.cardActionTransfer(transfer.type) ? (
                    <CardRequisites
                        type={transfer.type}
                        cardid={transfer.account?.cardid || transfer.account?.dstcardid}
                        onlyCardName
                    />
                ) : transfer.type === 'CryptoUSDT' ? (
                    `${capitalizeFirstLetter(transfer.account?.network)} (${FormatService.reduceCryptoAddress(
                        transfer.account?.address,
                    )})`
                ) : (
                    transfer.account?.account ||
                    (transfer.account?.consumerId && (
                        <>
                            <div>{transfer.account?.firstName + ' ' + transfer.account?.lastName}</div>
                            <div>{transfer.account?.consumerId}</div>
                        </>
                    ))
                )
            }
        } catch (e) {
            Bugsnag.notify(e)
            return 'INVALID PAYEE ACCOUNT'
        }
    },
    transfer2comment: (transfer: UnionProcessedTransfer) => {
        try {
            return IsService.cardActionTransfer(transfer.type)
                ? FormatService.transferType(transfer.type)
                : transfer.desc
        } catch (e) {
            Bugsnag.notify(e)
            return 'INVALID COMMENT'
        }
    },
    transferType: (type: TransferType) => {
        return (
            {
                IFX: 'Bank transfer',
                IFXIn: 'Bank transfer',
                PaxumP2P: 'Wallet transfer',
                SWIFT: 'Bank transfer',
                BANK_VND: 'NAPAS & CITAD transfer',
                PaysafeNeteller: 'Neteller transfer',
                PaysafeSkrill: 'Skrill transfer',
                ClearJunction: 'Bank transfer',
                SEPA: 'Bank transfer',
                CryptoUSDT: 'USDT transfer',
                WORLDCARD: 'World card transfer',
                NuveiCardOut: 'Nuvei transfer',
                PRACTIKA: 'Internal transfer',
                ActivatePlastic1: 'Card activation',
                TopUp1: 'Card top up',
                ChangePin1: 'Card password change',
                Block1: 'Card blocking',
                Change3ds1: 'Card change 3ds password',
                PlasticCard1: 'Physical card issue',
                VirtualCard1: 'Virtual card issue',
                '': 'Exchange',
                FX: 'Exchange',
                UNKNOWN: 'Exchange',
                SERVICE: 'Bank services',
                // Cards
                send_credit: 'Details',
                Card2Card1: 'Card to card',
            }[type] || (type === undefined ? 'Exchange' : type)
        )
    },
    isoDate2expiration: (date: string) => {
        if (date) {
            const d = date.split('T')[0].split('-')
            return `${d[1]}/${d[0]}`
        }
    },
    accountNumber: (account: string) => {
        try {
            return `${account.slice(0, 3)} ${account.slice(3, 7)} ${account.slice(7, 11)} ${account.slice(
                11,
                15,
            )} ${account.slice(15, 20)}`
        } catch (e) {
            return 'INVALID ACCOUNT NUMBER'
        }
    },
    bankTransfer2detailsTable: (transfer: any) => {
        try {
            return (
                <>
                    <tr>
                        <SectionTitle colSpan={2}>
                            <FormattedMessage defaultMessage="PAYMENT INFORMATION" />
                        </SectionTitle>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Payment reference number" />:
                        </td>
                        <td>{transfer.payment_reference}</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Payment type" />:
                        </td>
                        <td>{transfer.payment_type}</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Payment amount" />:
                        </td>
                        <td>{transfer.amount + ' ' + transfer.currency}</td>
                    </tr>
                    {transfer?.fee !== undefined && (
                        <tr>
                            <td>
                                <FormattedMessage defaultMessage="Payment fee" />:
                            </td>
                            <td>{transfer.fee + ' ' + transfer.currency}</td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Payment date" />:
                        </td>
                        <td>{transfer.payment_date}</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Execution date" />:
                        </td>
                        <td>{transfer.payment_execution_date || 'N/A'}</td>
                    </tr>
                    {transfer.payment_charges && (
                        <tr>
                            <td>
                                <FormattedMessage defaultMessage="Charges" />:
                            </td>
                            <td>{transfer.payment_charges}</td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Payment status" />:
                        </td>
                        <td>{transfer.payment_status}</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Payment details" />:
                        </td>
                        <td>{transfer.payment_details}</td>
                    </tr>
                    <tr>
                        <SectionTitle topSpace colSpan={2}>
                            <FormattedMessage defaultMessage="PAYER INFORMATION" />
                        </SectionTitle>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Payer account" />:
                        </td>
                        <td>
                            <b>{transfer.payer_account}</b>
                        </td>
                    </tr>
                    {transfer.payer_address && (
                        <tr>
                            <td>
                                <FormattedMessage defaultMessage="Payer address" />:
                            </td>
                            <td>{transfer.payer_address}</td>
                        </tr>
                    )}
                    {transfer.payer_account_number && (
                        <tr>
                            <td>
                                <FormattedMessage defaultMessage="Payer account number" />:
                            </td>
                            <td>
                                <b>{transfer.payer_account_number}</b>
                            </td>
                        </tr>
                    )}
                    {transfer.payer_bank && (
                        <tr>
                            <td>
                                <FormattedMessage defaultMessage="Payer bank" />:
                            </td>
                            <td>{transfer.payer_bank}</td>
                        </tr>
                    )}
                    {transfer.payer_bank_bic && (
                        <tr>
                            <td>SWIFT/BIC:</td>
                            <td>{transfer.payer_bank_bic}</td>
                        </tr>
                    )}
                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <FormattedMessage defaultMessage="Payer bank address" />:*/}
                    {/*    </td>*/}
                    {/*    <td>{payer.bankAddress}</td>*/}
                    {/*</tr>*/}
                    <tr>
                        <SectionTitle topSpace colSpan={2}>
                            <FormattedMessage defaultMessage="RECIPIENT INFORMATION" />
                        </SectionTitle>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Beneficiary name" />:
                        </td>
                        <td>
                            <b>{transfer.recipient_benificiary_name}</b>
                        </td>
                    </tr>
                    {transfer.recipient_benificiary_address && (
                        <tr>
                            <td>
                                <FormattedMessage defaultMessage="Beneficiary address" />:
                            </td>
                            <td>{transfer.recipient_benificiary_address}</td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Beneficiary account number" />:
                        </td>
                        <td>
                            <b>{transfer.beneficiary_account_number}</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Beneficiary bank" />:
                        </td>
                        <td>{transfer.beneficiary_bank}</td>
                    </tr>
                    <tr>
                        <td>SWIFT/BIC:</td>
                        <td>{transfer.beneficiary_bank_bic}</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Beneficiary bank address" />:
                        </td>
                        <td>{transfer.beneficiary_bank_address}</td>
                    </tr>
                </>
            )
        } catch (e) {
            console.error('INVALID BANK TRANSFER DETAILS', e)
            return 'INVALID BANK TRANSFER DETAILS'
        }
    },
}

export const SectionTitle = styled.td<{ topSpace?: boolean }>`
    font-weight: 700 !important;
    font-size: 16px !important;
    padding-bottom: 12px !important;
    color: var(--main-text-color) !important;
    text-align: left !important;
    border-bottom: none !important;

    &.topSpace {
        padding-top: 35px !important;
    }
`

export default FormatService
